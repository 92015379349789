<template>
	<div class="bg-Hight">
		<!-- 筛选 -->
		<div class="crud">
			<ul class="crud-list">
				<li>
					<el-button size="mini" @click="getList">刷新</el-button>
				</li>
				<li v-permission="['joindel']">
					<el-button @click="alldele(1)" class="el-white" size="mini" type="danger">删除</el-button>
				</li>
				<li>
					<el-input @clear="reset" type="text" clearable v-model="form.keyword" placeholder="请输入姓名">
					</el-input>
				</li>
				<li>
					<el-select clearable v-model="form.status" placeholder="请选择状态">
						<el-option label="全部" value=""></el-option>
						<el-option label="审核中" :value="0"></el-option>
						<el-option label="已通过" :value="1"></el-option>
						<el-option label="不通过" :value="2"></el-option>
					</el-select>
				</li>
				<li>
					<el-button @click="search" color="#488bff" class="el-white" style="color: white" size="mini">搜索
					</el-button>
				</li>
				<li>
					<el-button @click="reset" size="mini" class="el-white" type="danger">清空</el-button>
				</li>
				<li>
					<el-button @click="exports(2)" color="#488bff" class="el-white" style="color: white"
						size="mini">导出Exl
					</el-button>
				</li>
			</ul>
		</div>
		<!-- 表格列表 -->
		<div class="table">
			<el-table element-loading-text="加载中..." v-loading="tableloading" :max-height="maxheight" border
				:data="tableData" @selection-change="handleSelectionChange">
				<el-table-column type="selection" width="80" />
				<el-table-column type="index" label="序号" />

				<el-table-column property="is_agricultural_worker_member" width="150" label="公司名称">
					<template #default="scope">
						{{scope.row.trade?scope.row.trade.company_name:""}}
					</template>
				</el-table-column>

				<el-table-column property="is_agricultural_worker_member" width="150" label="是否是农民工会会员">
					<template #default="scope">
						<span v-if="scope.row.is_agricultural_worker_member==1">是</span>
						<span v-if="scope.row.is_agricultural_worker_member==0">否</span>
					</template>
				</el-table-column>
				<el-table-column property="name" label="姓名">
				</el-table-column>
				<el-table-column property="gender" label="性别">
				</el-table-column>
				<el-table-column property="mobile_number" label="手机号码">
				</el-table-column>
				<el-table-column property="birth_date" label="出生日期">
				</el-table-column>
				<el-table-column property="id_number" label="身份证号">
				</el-table-column>
				<el-table-column property="ethnicity" label="民族">
				</el-table-column>
				<el-table-column property="union_roles" label="工会身份">
					<template #default="scope">
						<span v-if="scope.row.union_roles==0">工会主席、副主席</span>
						<span v-if="scope.row.union_roles==1">女职工委员会主任、副主任</span>
						<span v-if="scope.row.union_roles==2">女职工委员会委员</span>
						<span v-if="scope.row.union_roles==3">经费审查委员主任、副主任</span>
						<span v-if="scope.row.union_roles==4">经费审查委员会委员</span>
						<span v-if="scope.row.union_roles==5">委员会委员</span>
					</template>
				</el-table-column>
				<el-table-column property="employment_status" label="就业状况">
					<template #default="scope">
						<span v-if="scope.row.employment_status==1">在岗</span>
						<span v-if="scope.row.employment_status==2">待(下)岗</span>
						<span v-if="scope.row.employment_status==3">失业</span>
						<span v-if="scope.row.employment_status==4">退休</span>
						<span v-if="scope.row.employment_status==5">退职</span>
					</template>
				</el-table-column>
				<el-table-column property="education" label="学历">
					<template #default="scope">
						<span v-if="scope.row.education==1">博士</span>
						<span v-if="scope.row.education==2">硕士</span>
						<span v-if="scope.row.education==3">本科</span>
						<span v-if="scope.row.education==4">大专</span>
						<span v-if="scope.row.education==5">中专</span>
						<span v-if="scope.row.education==6">高中</span>
						<span v-if="scope.row.education==7">初中</span>
						<span v-if="scope.row.education==8">小学及以下</span>
					</template>
				</el-table-column>
				<el-table-column property="tech_level" label="技术等级">
					<template #default="scope">
						<span v-if="scope.row.tech_level==0">无</span>
						<span v-if="scope.row.tech_level==1">职业资格一级（高级技师)</span>
						<span v-if="scope.row.tech_level==2">职业资格二级(技师)</span>
						<span v-if="scope.row.tech_level==3">职业资格三级(高级)</span>
						<span v-if="scope.row.tech_level==4">职业资格四级（中级)</span>
						<span v-if="scope.row.tech_level==5">职业资格五级（初级)</span>
					</template>
				</el-table-column>
				<el-table-column property="workplace" label="工作单位">
				</el-table-column>
				<el-table-column property="native" label="籍贯">
				</el-table-column>
				<el-table-column property="household_type" label="户籍类型">
					<template #default="scope">
						<span v-if="scope.row.household_type==1">农业户口</span>
						<span v-if="scope.row.household_type==2">非农业户口</span>
					</template>
				</el-table-column>
				<el-table-column property="household_location" width="120" label="户籍所在地">
				</el-table-column>
				<el-table-column property="membership_change_type" width="120" label="户籍变化类型">
					<template #default="scope">
						<span v-if="scope.row.membership_change_type==0">入会</span>
						<span v-if="scope.row.membership_change_type==1">转入</span>
						<span v-if="scope.row.membership_change_type==2">保留</span>
						<span v-if="scope.row.membership_change_type==3">转出</span>
						<span v-if="scope.row.membership_change_type==4">除名</span>
						<span v-if="scope.row.membership_change_type==5">退会</span>
					</template>
				</el-table-column>
				<el-table-column property="membership_change_date" width="120" label="户籍变化日期">
				</el-table-column>
				<el-table-column property="membership_change_reason" width="120" label="户籍变化原因">
					<template #default="scope">
						<span v-if="scope.row.membership_change_reason==1">劳动(工作)关系发生变化</span>
						<span v-if="scope.row.membership_change_reason==2">在本单位下岗后待岗</span>
						<span v-if="scope.row.membership_change_reason==3">失业</span>
						<span v-if="scope.row.membership_change_reason==4">所在企业破产</span>
						<span v-if="scope.row.membership_change_reason==5">个人要求退会</span>
						<span v-if="scope.row.membership_change_reason==6">因犯罪被开除会籍</span>
					</template>
				</el-table-column>
				<el-table-column property="political_affiliation" label="政治面貌">
					<template #default="scope">
						<span v-if="scope.row.political_affiliation==1">中共党员</span>
						<span v-if="scope.row.political_affiliation==2">中共预备党员</span>
						<span v-if="scope.row.political_affiliation==3">共青团员</span>
						<span v-if="scope.row.political_affiliation==4">民革党员</span>
						<span v-if="scope.row.political_affiliation==5">民盟盟员</span>
						<span v-if="scope.row.political_affiliation==6">民建会员</span>
						<span v-if="scope.row.political_affiliation==7">民进会员</span>
						<span v-if="scope.row.political_affiliation==8">农工党党员</span>
						<span v-if="scope.row.political_affiliation==9">致公党党员</span>
						<span v-if="scope.row.political_affiliation==10">九三学社社员</span>
						<span v-if="scope.row.political_affiliation==11">台盟盟员</span>
						<span v-if="scope.row.political_affiliation==12">无党派人士</span>
						<span v-if="scope.row.political_affiliation==13">群众</span>
					</template>
				</el-table-column>
				<el-table-column property="failure_reason" width="140" label="不通过原因">
				</el-table-column>
				<el-table-column property="status" label="状态">
					<template #default="scope">
						<el-tag v-if="scope.row.status==0">审核中</el-tag>
						<el-tag type="success" v-if="scope.row.status==1">已通过</el-tag>
						<el-tag v-if="scope.row.status==2" type="danger">不通过</el-tag>
					</template>
				</el-table-column>
				<el-table-column property="create_time" width="145" label="创建时间" />
				<el-table-column property="address" fixed="right" label="操作" width="200">
					<template #default="scope">
						<el-button v-permission="['joininfo']" @click="bindread(scope.row.id)" size="mini">查看详情
						</el-button>
						<el-button v-permission="['joinedits']" @click="binedits(scope.row.id)" size="mini">编辑工会身份
						</el-button>
						<el-button v-permission="['joindel']" size="mini" @click="dele(scope.row.id)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<!--分页 -->
			<div class="pages">
				<el-pagination background v-model="form.page" :page-sizes="[15, 20, 30, 40,50,100]"
					:page-size="form.limit" layout="sizes, prev, pager, next, jumper" :total="total"
					@size-change="handleSizeChange" @current-change="handleCurrentChange">
				</el-pagination>
			</div>
		</div>
		<!-- 详情 -->
		<read ref="read" @SubmitForm="getList"></read>
		<!-- 编辑 -->
		<edits ref="edits" @SubmitForm="getList" />
	</div>
</template>

<script>
	import {
		ElMessageBox,
		ElMessage
	} from 'element-plus'
	import {
		request,
		api
	} from "@/api/word";
	import read from '@/components/userread.vue'
	import edits from '@/components/useredits.vue'
	export default {
		components: {
			read,
			edits
		},
		data() {
			return {
				maxheight: document.documentElement.clientHeight - 232 + 'px',
				total: 0,
				form: {
					keyword: '',
					page: 1,
					limit: 15,
					trade_id: "",
					status: ""
				},
				tableData: [],
				idlist: [],
				type: 1
			}
		},
		created() {
			this.getList()
		},
		methods: {
			// 编辑
			binedits(id) {
				this.$refs.edits.show(id)
			},
			// 导出
			exports(val) {
				this.type = val
				if (this.idlist.length == 0) {
					ElMessage({
						message: '请选择需要导出数据',
						type: 'warning',
					})
				} else {
					this.$http.post(api.export, {
							trade_id: this.idlist.join(',')
						})
						.then((res) => {
							window.location.href = res.data.url
						})
				}
			},
			// 图片预览
			srcList(url) {
				let list = []
				if (url) {
					list = url.split(",")
					return list
				} else {
					return list
				}
			},
			handleSizeChange(e) {
				this.form.limit = e
				this.getList()
			},
			// 分页
			handleCurrentChange(e) {
				this.form.page = e
				this.getList()
			},
			// 清空
			reset() {
				for (let key in this.form) {
					this.form[key] = ''
				}
				this.form.limit = 15
				this.form.page = 1
				this.getList()
			},
			// 搜索
			search() {
				this.form.page = 1
				this.getList()
			},
			// 表格全选
			handleSelectionChange(e) {
				this.idlist = []
				e.forEach(item => {
					this.idlist.push(this.type == 1 ? item.id : item.trade_id)
				})
			},
			// 获取列表
			getList() {
				this.tableloading = true
				this.$http.post(api.getPageList, this.form)
					.then((res) => {
						if (res.code == 200) {
							this.tableData = res.data.list
							this.total = res.data.count
							this.tableloading = false
						}
					})
			},
			// 详情
			bindread(id) {
				this.$refs.read.show(id)
			},
			// 批量删除
			alldele(val) {
				this.type = val
				if (this.idlist.length == 0) {
					ElMessage({
						message: '请选择需要删除数据',
						type: 'warning',
					})
				} else {
					ElMessageBox.confirm(
							'此操作将永久删除?',
							'提示', {
								confirmButtonText: '确定',
								cancelButtonText: '取消',
								type: 'warning',
							}
						)
						.then(() => {
							this.$http.post(api.delete, {
									id: this.idlist.join(',')
								})
								.then((res) => {
									if (res.code == 200) {
										ElMessage({
											type: 'success',
											message: '删除成功',
										})
										this.form.page = 1
										this.getList()
									}
								})
						})
						.catch(() => {
							// 取消
						})
				}
			},
			// 删除
			dele(id) {
				ElMessageBox.confirm(
						'此操作将永久删除?',
						'提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning',
						}
					)
					.then(() => {
						this.$http.post(api.delete, {
								id: id
							})
							.then((res) => {
								if (res.code == 200) {
									ElMessage({
										type: 'success',
										message: '删除成功',
									})
									this.form.page = 1
									this.getList()
								}
							})
					})
					.catch(() => {
						// 取消
					})
			}
		}
	}
</script>

<style scoped>
	.contents {
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 3;
		overflow: hidden;
	}

	video {
		width: 100%;
		height: 100px;
	}
</style>